
@use '@angular/material' as mat;


@include mat.core();





img{
    max-width: 100%;
}
html, body { height: 100%; }

@font-face {
  font-family: 'Poppins-Light';
  font-weight: 300;
  src:  url('./assets/fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  font-weight: 700;
  src:  url('./assets/fonts/Poppins/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src:  url('./assets/fonts/Poppins/Poppins-Regular.ttf');
}


body { margin: 0; font-family: 'Arial', sans-serif !important; .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  line-height: normal;
  pointer-events: all;
  color: gray !important;
}
mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix label{
  color: gray !important;
} 
textarea::placeholder{
  color: gray !important;

}
}


:root{
  --bg-color: linear-gradient(117.15deg, #971CA1 0%, #715FAD 39.74%, #607FB3 69.15%, #45AEBB 99.08%);
  --green-text-color:#68B743;
  --bg-btn: #57aec0;
  --btn-cancel-border: 1px solid #57aec0;
  --btn-cancel-color: #57aec0;

  --font-family: 'Poppins';
  --btn-bg-color:linear-gradient(180deg, #88BF3E 0%, #51B247 100%);
  --white-color : #ffffff ;
  --black-mix-color : rgba(0, 0, 0, 0.55) ;
  --skyblue-color : #0087CB;
  --black-color : #000000;
  --grayish-blue-color : #adb0bd;
  --pale-blue-gray-color : #f1f3f8 ;
  --light-azure-blue-color : #3aa2fb ; 
  --purple-and-blue-color : linear-gradient(180deg, #DCB4E2 0%, #9486E7 100%);
  --box-shadow-color : 0 0 1px #DDEFFD;
  --Bright-Sky-Blue-color : #2699fb ; 
  --light-lavender-gray-color : #f5f6fa;
  --Pale-Lavender-Gray-color : 1px solid #f1f1f3;
  --Crimson-Red-color : #f4465a ;
  --Soft-Lavender-color : linear-gradient(354deg, #CE89D6 0%, #48B3BE 100%);
  --Lavender-to-Teal-Gradient-color : linear-gradient(90deg, #CE89D6 0%, #48B3BE 100%);
  --Left-to-Right-Fade : linear-gradient(to right, #4ab2be 50%, rgba(255, 255, 255, 0) 0%);
  --Top-to-Bottom-Fade : linear-gradient(to bottom, #4ab2be 50%, rgba(255, 255, 255, 0) 0%);
  --Horizontal-Gradient-from-Purple-to-Muted-Green-color : linear-gradient(180deg, #A22DCD 0%, #699962 100%);
  --Horizontal-Gradient-from-Vibrant-Green-to-Deep-Blue-color : linear-gradient(180deg, #09E624 0%, #103FCD 100%);
  --Horizontal-Gradient-from-Blue-to-Muted-Pink-Purple-color : linear-gradient(180deg, #6064E8 0%, #CC63BE 100%);
  --Horizontal-Gradient-from-Magenta-to-Yellow-color : linear-gradient(180deg, #E423C2 0%, #ECD01C 100%);
  --Horizontal-Gradient-from-Light-Grayish-Blue-to-Medium-Blue-color : linear-gradient(180deg, #E2E2E4 0%, #5977E0 100%);
  --Horizontal-Gradient-from-Dark-Magenta-Purple-to-Medium-Blue-color : linear-gradient(180deg, #9A16A0 0%, #627AB2 100%);
  --Horizontal-Gradient-from-Light-Lavender-to-Medium-Teal-color :  linear-gradient(180deg, #CE89D6 0%, #48B3BE 100%);
  --Powder-Blue-color : #F7FBFF;
  --pastel-sky-blue-color : #BCE0FD;
  --Very-Dark-Black-with-Transparency : #0000005e;
  --Very-Dark-Black-with-Transparency-color : #0000005c;
  --solid-grey-color : 1px solid gray;
  --dark-cyan-green-color : #36bea6;
  --Light-Blue-Gray-color : #e3e5ef;
  --Light-Gray-color : #ddd;
  --Soft-Blue-color : #cde1ea;
  --Light-Blue-Border-color : #cde1eb;
  --Hot-Pink-color : #ff4081;
  --light-green-color : #4CAF50;
  --red-color : red;
  --Bright-Red-color : #ff5959;
  --Turquoise-color : #4ab2be;
  --Light-Blue-Dashed-Border-color : #cde1ec;
  --Muted-Gray-color : #9b9b9b;
  --shade-of-cyan-color : #58aec0;
  --semi-transparent-black-color : rgba(0,0,0,.87);
  --dark-shade-of-cyan-or-teal-color : #4bb2bf;
  --Steel-Blue-color : #48B3BE;
  --grey-color : grey;
  --green-color : green;
  --rgb-black-color : rgb(0, 0, 0);
  --rgb-white-color : rgb(255, 255, 255);
  --shade-of-green : #008000;
  --rgb-red-color : #ff0000;
  --light-shade-of-blue : 1px solid #cde1eb;
  --medium-to-dark-shade-of-gray : #505050;
  --shade-of-rgb-blue : #3989b0;
  --light-grey : lightgray;
  --very-light-shade-of-blue : #f1f9ff;
  --shade-of-black-with-partial-transparency : rgba(0, 0, 0, 0.82);
  --shade-of-blue : #2196F3;
  --shade-of-sky-blue : #32A4FB;
  --light-or-medium-sky-blue : #5fa2db;
  --Silver-color : #e2e2e2;
  --very-light-shade-of-black-with-partial-transparency : rgba(0,0,0,.06);
  --Dim-Gray : #555;
  --high-opacity-black-color : rgba(0, 0, 0, 0.87);
  --shade-of-purple : #c78bd5;
  --Silver-or-Light-Gray : #cdcdcd;
  --medium-to-dark-chartreuse-green : #7BBB41;
  --dark-shade-of-grayish-blue : #4E4E54;
  --Ash-Gray-color :  #808184;
  --dark-shade-of-blue-gray : #55556D;
  --Light-GrayORSilver-color : #E2E2E4;
  --offwhite-color : #EFEFF2;
  --CharcoalGray-Color : #78797C;
  --vibrant-and-fresh-green :  #7bbc41;
  --lightshadeofgrayandsilver-color : #D9D9D9;
  --mediumshadeofGray-or-Dark-Gray : #9e9e9e;
  --White-or-Snow-color : #fdfdfd;
  --newShadeofBlue-color : #9cc3d5;
  --shade-of-blue-fully-transparent-color : #5ca3dd93;
  --RGBA-color : #eaeaead4;
  --lightShade-of-blue-color : #cde1ed;
  --newGreenColor : #00d500;
  --orange-color : orange;
  --Charcoal-Gray-or-simply-Gray-color : #6f6f6f;
  --violet-color : #cc89d6;
  --White-Smoke-color : #F8F9FA;
  --muted-shade-of-blue-gray-color : #B1B9C0;
  --dark-blue-color : #004AAD;
  --shadeofblack-color : rgba(0, 0, 0, 0.43);
  --newWhite-color : #E4F2FF;
  --very-lightshadeofblue-color : #f1f8fe;
  --darkShadeOfBlack-color : rgba(0, 0, 0, 0.39);
  --newBlack-color : rgba(0, 0, 0, 0.71);
  --shade-of-teal-or-turquoise-color : #4ab3be;
  --low-grey-color : #cfcfcf;
  --pastel-or-sky-blue-color : #D7E7FF;
  --medium-to-light-shadeoftealor-turquoise : #55afc0;
  --vivid-or-electric-blue-color : rgb(13, 173, 237);
  --light-lavender-color : #ce8ad6;
  --Light-grey-or-offWhite-color : #eeeeee;
  --off-white-color : #ccc;
  --White-Smoke-or-white : #f9f9f9;
  --Anti-Flash-White : #FAFAFA;
  --rgb-dark-black : rgba(0, 0, 0, 0.2);
  --semitransparent-black-color : rgba(0, 0, 0, 0.3607843137);
  --dark-or-deep-red-color :#b02a37;




}

*{
  font-weight: 400;
}

mat-form-field{
  ::ng-deep{
    .mat-mdc-form-field-focus-overlay {
      display: none;
  }
  }
}

.ngx-pagination .current{
  background-color: var(--shade-of-cyan-color) !important;
  padding: 3px 10px;
  border-radius: 3px;
}
.has-subnav-sidemenu{
  .mat-expansion-panel-spacing{
    margin: 8px 0 !important;
  }
}
::ng-deep{
  .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mdc-floating-label--float-above {
    max-width: calc(100% / 0.75 - 24px);
    top: 16px !important;
    font-size: 14px !important;
}


}

::ng-deep .data-table{
  table{
      thead{
  tr:hover{
      background: var(--Lavender-to-Teal-Gradient-color) !important;
  }
      }
  }
}